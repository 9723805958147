import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "react-modal";

const Modulo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [curso, setCurso] = useState({ Introduccion: "", modulos: [] });
  const [loading, setLoading] = useState(true);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [introText, setIntroText] = useState("");
  const [isEditingIntro, setIsEditingIntro] = useState(false);
  const fetchCurso = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}cursos/sesion/${id}/modulos/sesion`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setCurso(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching curso:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCurso();
  }, [id]);

  const handleOpenIntroModal = () => {
    setIsIntroModalOpen(true);
  };

  const handleCloseIntroModal = () => {
    setIsIntroModalOpen(false);
    setIsEditingIntro(false);
  };

  const handleSaveIntro = async () => {
    try {
      const url = isEditingIntro
        ? `${window.BACKEND_URL}cursos/${id}/introduccion/editar`
        : `${window.BACKEND_URL}cursos/${id}/introduccion/sesion`;

      const method = isEditingIntro ? "put" : "post";

      await axios({
        method: method,
        url: url,
        data: { introduccion: introText },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });

      setCurso({ ...curso, Introduccion: introText });
      handleCloseIntroModal();
    } catch (error) {
      console.error("Error saving introduction:", error);
    }
  };

  const handleEditIntro = () => {
    setIntroText(curso.Introduccion);
    setIsEditingIntro(true);
    handleOpenIntroModal();
  };

  const handleAddModulo = () => {
    navigate(`/AddModulo/${id}/${curso?._id}`);
  };

  const editarModulo = (moduloid) => {
    navigate(`/editModulo/${moduloid}/${curso?.Curso_id}`);
  };

  const addConte = (moduloid) => {
    navigate(`/contenido/curso/${moduloid}/${curso?.Curso_id}`);
  };

  const handleDeleteModulo = async (moduloId) => {
    try {
      await axios.delete(
        `${window.BACKEND_URL}modulo/sesion/${id}/eliminar/${moduloId}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      fetchCurso();
    } catch (error) {
      console.error("Error deleting module:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <span className="float-end">
            {!curso?.Introduccion || curso.Introduccion.trim() === "" ? (
              <button
                className="btn btn-primary"
                onClick={handleOpenIntroModal}
              >
                <i className="fa-solid fa-plus"></i> Agregar Introducción
              </button>
            ) : (
              <button className="btn btn-primary" onClick={handleAddModulo}>
                <i className="fa-solid fa-plus"></i> Agregar Módulos
              </button>
            )}
          </span>
          <h5 className="card-title mb-0">Módulos</h5>
        </div>
        <div className="card-body">
          {curso?.Introduccion && (
            <div className="mb-4">
              <h6>Introducción</h6>
              <div
                style={{
                  maxHeight: "200px", // Adjust the max height as needed
                  overflowY: "auto",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
                dangerouslySetInnerHTML={{ __html: curso.Introduccion }}
              />
              <button
                className="btn btn-secondary mt-2"
                onClick={handleEditIntro}
              >
                Editar Introducción
              </button>
            </div>
          )}
          {curso?.modulos && curso.modulos.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Título</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {curso.modulos.map((modulo, index) => (
                  <tr key={modulo._id}>
                    <td>{index + 1}</td>
                    <td>{modulo.titulo}</td>
                    <td>
                      <button
                        className="btn btn-secondary"
                        onClick={() => editarModulo(modulo.moduloId)}
                      >
                        Editar
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => addConte(modulo.moduloId)}
                      >
                        Agregar contenido
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteModulo(modulo.moduloId)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No hay módulos disponibles.</p>
          )}
        </div>
      </div>

      <Modal
        isOpen={isIntroModalOpen}
        onRequestClose={handleCloseIntroModal}
        contentLabel="Agregar Introducción"
        ariaHideApp={false}
      >
        <h2>
          {isEditingIntro ? "Editar Introducción" : "Agregar Introducción"}
        </h2>
        <ReactQuill value={introText} onChange={setIntroText} />
        <button className="btn btn-primary mt-3" onClick={handleSaveIntro}>
          Guardar
        </button>
        <button
          className="btn btn-secondary mt-3"
          onClick={handleCloseIntroModal}
        >
          Cancelar
        </button>
      </Modal>
    </>
  );
};

export default Modulo;
