import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const AddBanersCurso = () => {
  const [bannerFile, setBannerFile] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [banners, setBanners] = useState([]);

  const navigate = useNavigate();

  const getBanners = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}curso/${id}/baners`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setBanners(response.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getBanners();
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setBannerFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select an image file.");
    }
  };

  const handleDelete = async (bannerId) => {
    try {
      await axios.delete(
        `${window.BACKEND_URL}curso/${id}/banners/${bannerId}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      await getBanners();
    } catch (error) {
      console.error("Error deleting banner:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!bannerFile) {
      alert("Please select an image file.");
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("banner", bannerFile);

      await axios.put(`${window.BACKEND_URL}curso/${id}/baner`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });

      getBanners();
    } catch (error) {
      console.error("Error adding banner:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar Banner</h3>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Imagen</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {banners.map((banner) => (
                  <tr key={banner.id}>
                    <td>
                      <img
                        src={`${window.BACKEND_URL}uploads/${banner.photo}`}
                        style={{ width: "150px", height: "auto" }}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(banner.idBaner)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Imagen del Banner:</label>
                    <input
                      type="file"
                      name="banner"
                      className="form-control"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              {bannerPreview && (
                <div className="row">
                  <div className="col-md-6">
                    <img
                      src={bannerPreview}
                      alt="Preview"
                      style={{ width: "100%", marginTop: "10px" }}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-between mt-2">
                <Link className="btn btn-primary" to="/Curso">
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Banner <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanersCurso;
