import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  //const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    const fetchLogin = async () => {
      try {
        const postData = {
          email: username,
          password: password,
        };
        const response = await axios.post(
          `${window.BACKEND_URL}login`,
          postData
        );
        if (response.status === 200) {
          //setToken(response.data.token);

          sessionStorage.setItem("userName", response.data.name);
          sessionStorage.setItem("role", response.data.role);
          // sessionStorage.setItem('userGuid', data.userGuid);
          // sessionStorage.setItem('roleId', data.roleId);
          // sessionStorage.setItem('tipIns', data.tipIns);
          sessionStorage.setItem("token", response.data.token);
          //console.log(sessionStorage, "null");
          navigate("/");
        }
      } catch (error) {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
      }
    };

    if (username && password) {
      fetchLogin();

      //setIsLoggedIn(true);
    } else {
      alert("Por favor, introduce un nombre de usuario y contraseña válidos");
    }
  };

  return (
    <>
      <div className="main d-flex justify-content-center w-100">
        <main className="content d-flex p-0">
          <div className="container d-flex flex-column">
            <div className="row h-100">
              <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                <div className="d-table-cell align-middle">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-center">
                        <img
                          src="img/logo/logostemisnormal.png"
                          className="w-75"
                        />
                      </div>
                      <div className="m-sm-3">
                        <div className="mb-3">
                          <label className="form-label">Correo</label>
                          <input
                            className="form-control form-control-lg"
                            type="email"
                            name="email"
                            placeholder="Ingrese su correo"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Contraseña</label>
                          <input
                            className="form-control form-control-lg"
                            type="password"
                            name="password"
                            placeholder="Ingrese su contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                          <button
                            className="btn btn-lg btn-primary"
                            onClick={handleLogin}
                          >
                            Ingresar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Login;
