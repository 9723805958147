import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const Addlink = () => {
  const [linkInput, setLinkInput] = useState("");
  const [linkName, setLinkName] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [links, setLinks] = useState([]);

  const navigate = useNavigate();

  const getLinks = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}curso/${id}/links`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setLinks(response.data);
    } catch (error) {
      console.error("Error fetching links:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getLinks();
      } catch (error) {
        console.error("Error al obtener los links:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setLinkInput(e.target.value);
  };

  const handleLinkNameChange = (e) => {
    setLinkName(e.target.value);
  };

  const handleDelete = async (idLink) => {
    try {
      await axios.delete(`${window.BACKEND_URL}curso/${id}/links/${idLink}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });
      await getLinks();
    } catch (error) {
      console.error("Error al eliminar el link:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await axios.put(
        `${window.BACKEND_URL}curso/${id}/link`,
        {
          enlace: linkInput,
          nombre: linkName, // Envia el link como un array
        },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      console.log("Link agregado exitosamente");
      navigate("/Addlink");
      navigate(`/Addlink/${id}`);
    } catch (error) {
      console.error("Error al agregar el link:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar Links</h3>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Links</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {links.map((link) => (
                  <tr key={link.idLink}>
                    <td>{link.nombre}</td>
                    <td>{link.enlace}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(link.idLink)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Nombre del Enlace:</label>
                    <input
                      type="text"
                      name="linkName"
                      className="form-control"
                      placeholder="Ingrese el nombre del enlace"
                      value={linkName}
                      onChange={handleLinkNameChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Enlace del Video:</label>
                    <input
                      type="text"
                      name="links"
                      className="form-control"
                      placeholder="Ingrese el enlace del video"
                      value={linkInput}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <Link className="btn btn-primary" to="/Curso">
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Link <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addlink;
