import { Link } from "react-router-dom";

const LayoutDocente = () => {
    return (<>

        <div className="sidebar-content js-simplebar">
            <a className="sidebar-brand" href="/blogs">
                <img src="img/logo/logoblanoo.svg" className="w-75" />
            </a>
            <ul className="sidebar-nav">
                {/* <li className="sidebar-item">
                    <Link to="/users" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Usuarios</span>
                    </Link>
                </li>
                <li className="sidebar-item">
                    <Link to="/blogs" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Blogs</span>
                    </Link>
                </li>
                <li className="sidebar-item">
                    <Link to="/Colabora" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Colaboradores</span>
                    </Link>
                </li> */}
                {/*
            <li className="sidebar-item">
              <Link to="/Facturas" className="sidebar-link">
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Facturas</span>
              </Link>
            </li>
            */}
                {/*
            <li className="sidebar-item">
              <Link to="/Tareas" className="sidebar-link">
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Tareas</span>
              </Link>
            </li>
            */}
                {/*
            <li className="sidebar-item">
              <Link to="/Cuenta" className="sidebar-link">
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Cuenta Corriente</span>
              </Link>
            </li>
            */}
                {/* <li className="sidebar-item">
                    <Link to="/Message" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Mensajes</span>
                    </Link>
                </li> */}
                <li className="sidebar-item">
                    <Link to="/Alumno" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Alumnos</span>
                    </Link>
                </li>
                <li className="sidebar-item">
                    <Link to="/Curso" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Cursos</span>
                    </Link>
                </li>
                <li className="sidebar-item">
                    <Link to="/Tareas" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Tareas</span>
                    </Link>
                </li>
                <li className="sidebar-item">
                    <Link to="/Matricula" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Matricula</span>
                    </Link>
                </li>
                {/* <li className="sidebar-item">
                    <Link to="/Baner" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Baner</span>
                    </Link>
                </li>
                <li className="sidebar-item">
                    <Link to="/Normativa" className="sidebar-link">
                        <i className="align-middle" data-feather="list"></i>{" "}
                        <span className="align-middle">Normatividad Basica</span>
                    </Link>
                </li> */}
            </ul>
        </div>
    </>
    );
};

export default LayoutDocente;
