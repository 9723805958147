import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import LoadingOverlay from "../components/LoadingOverlay"; // Asegúrate de importar correctamente el componente

const Normativa = () => {
  const navigate = useNavigate();
  const [normativas, setNormativas] = useState([]);
  const [loading, setLoading] = useState(false);

  const getNormativas = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}normatividad/datos`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setNormativas(response.data);
    } catch (error) {
      console.error("Error fetching normativas:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getNormativas();
        //initializeDataTable();
      } catch (error) {
        console.error("Error al obtener los baners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (
        !tablanorma.current ||
        !$.fn.DataTable ||
        !$.fn.DataTable.isDataTable(tablanorma.current)
      ) {
        return;
      }

      $(tablanorma.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };
    initializeDataTable();
  }, [loading]);

  const handleDelete = async (normativaId) => {
    try {
      await axios.delete(`${window.BACKEND_URL}normatividad/${normativaId}`);
      // Actualizar la lista de normativas después de eliminar
      await getNormativas();
    } catch (error) {
      console.error("Error al eliminar la normativa:", error);
    }
  };

  const tablanorma = useRef(null);

  const Editnorma = (id) => {
    navigate(`/Editnorma/${id}`);
  };

  const Editnormacard = (id) => {
    navigate(`/Editnormativacard/${id}`);
  };

  const Editnormaimagen = (id) => {
    navigate(`/Editnormativaimagen/${id}`);
  };

  const Editnormapdf = (id) => {
    navigate(`/Editnormativapdf/${id}`);
  };

  return (
    <>
      <div className="position-relative">
        {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
        {!loading && (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <span className="float-end">
                    <Link to="/Addnorma" className="btn btn-primary">
                      <i className="fa-solid fa-plus"></i> Agregar Norma
                    </Link>
                  </span>
                  <h5 className="card-title mb-0">Normatividad</h5>
                </div>
                <hr />

                <div className="card-body">
                  <table className="table table-bordered" ref={tablanorma}>
                    <thead>
                      <tr>
                        <th>Foto</th>
                        <th>Tags</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {normativas.map((normativa) => (
                        <tr key={normativa._id}>
                          <td>
                            <img
                              className="w-25"
                              src={`${window.BACKEND_URL}uploads/${normativa.foto_card}`}
                              alt="Foto del "
                            />
                          </td>
                          <td>{normativa.tags}</td>
                          <td className="text-center">
                            <div class="dropdown">
                              <a
                                class="btn btn-sm btn-primary"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="fas fa-ellipsis-v fa-lg"></i>
                              </a>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <a
                                    class="dropdown-item text-primary"
                                    onClick={() => Editnorma(normativa._id)}
                                  >
                                    <i class="fa-solid fa-pen-to-square"></i>{" "}
                                    Editar
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item text-primary"
                                    onClick={() => Editnormacard(normativa._id)}
                                  >
                                    <i class="fa-solid fa-image"></i> Editar
                                    card
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item text-primary"
                                    onClick={() =>
                                      Editnormaimagen(normativa._id)
                                    }
                                  >
                                    <i class="fa-solid fa-images"></i> Editar
                                    portada
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item text-primary"
                                    onClick={() => Editnormapdf(normativa._id)}
                                  >
                                    <i class="fa-solid fa-file-pdf"></i> Editar
                                    pdf
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item text-danger"
                                    onClick={() => handleDelete(normativa._id)}
                                  >
                                    <i class="fa-solid fa-folder"></i> Eliminar
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Normativa;
