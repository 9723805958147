import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Oval } from "react-loader-spinner";
import ReactModal from "react-modal";

const AddNovedadesTorneo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ content: "", tipo: 0 }); // Default type to text
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [novedades, setNovedades] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editingNovedad, setEditingNovedad] = useState(null); // State to hold the currently editing novedad
  console.log(formData, "dattos");
  // Function to load novedades
  const loadNovedades = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}torneo/${id}/novedades`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      setNovedades(response.data);
    } catch (error) {
      console.error("Error al cargar las novedades:", error);
    }
  };

  useEffect(() => {
    loadNovedades();
  }, []);

  // Function to open modal for editing novedad
  const openModal = (novedad) => {
    setEditingNovedad(novedad);
    setModalIsOpen(true);
    setFormData({ content: novedad.content, tipo: novedad.tipo });
    if (novedad.tipo === 1) {
      setFile(novedad.content);
    }
  };

  // Function to close modal
  const closeModal = () => {
    setEditingNovedad(null);
    setModalIsOpen(false);
    setFormData({ content: "", tipo: 2 });
    setFile(null); // Clear selected file
  };

  // Function to handle changes in content (text or image)
  const handleContentChange = (content) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      content,
    }));
  };

  // Function to handle file change for image upload
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFormData({ ...formData, tipo: 1 });
  };

  // Function to handle form submission for adding or editing novedad
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let data;
      if (formData.tipo === 1 && file) {
        const imageFormData = new FormData();
        imageFormData.append("file", file);
        imageFormData.append("tipo", 1);
        data = imageFormData;
      } else {
        data = { content: formData.content, tipo: formData.tipo };
      }

      await axios.put(
        `${window.BACKEND_URL}torneo/${id}/novedades/novedad`,
        data,
        {
          headers: {
            "Content-tipo":
              formData.tipo === 1 ? "multipart/form-data" : "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      // Refresh novedades after adding/editing
      loadNovedades();
      closeModal();
    } catch (error) {
      console.error("Error al agregar/editar la novedad:", error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle deletion of novedad
  const handleDelete = async (novedadId) => {
    try {
      const response = await axios.delete(
        `${window.BACKEND_URL}torneo/${id}/novedades/${novedadId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      if (response.status === 200) {
        alert("Novedad eliminada correctamente");
        // Refresh novedades after deletion
        loadNovedades();
      } else {
        alert("Hubo un problema al eliminar la novedad");
      }
    } catch (error) {
      console.error("Error al eliminar la novedad:", error);
      alert("Error al eliminar la novedad");
    }
  };

  // Function to handle editing of novedad
  const handleEdit = async () => {
    if (!editingNovedad) return;

    if (formData.tipo === 1 && !file) {
      alert("Debe seleccionar una nueva imagen.");
      return;
    }

    setLoading(true);

    try {
      let editData;
      if (formData.tipo === 1 && file) {
        const imageFormData = new FormData();
        imageFormData.append("file", file);
        imageFormData.append("tipo", 1);
        editData = imageFormData;
      } else {
        editData = { content: formData.content, tipo: formData.tipo };
      }

      await axios.put(
        `${window.BACKEND_URL}torneo/${id}/novedades/${editingNovedad.NovedadId}`,
        editData,
        {
          headers: {
            "Content-tipo":
              formData.tipo === 1 ? "multipart/form-data" : "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      // Close modal and refresh novedades after editing
      closeModal();
      loadNovedades();
    } catch (error) {
      console.error("Error al editar la novedad:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleContentTypeChange = (e) => {
    const newType = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      tipo: newType,
    }));
    if (newType !== 1) {
      setFile(null);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Agregar Una Novedad</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="contentType" className="form-label">
                    Tipo de Contenido
                  </label>
                  <select
                    id="contentType"
                    className="form-select"
                    value={formData.tipo}
                    onChange={handleContentTypeChange}
                  >
                    <option value={2}>Texto</option>
                    <option value={1}>Imagen</option>
                    <option value={3}>YouTube</option>
                    <option value={4}>YouTube SHORTS</option>
                  </select>
                </div>
                {formData.tipo === 2 ? (
                  <div className="mb-3">
                    <label htmlFor="textContent" className="form-label">
                      Agregar Texto
                    </label>
                    <ReactQuill
                      value={formData.content}
                      onChange={handleContentChange}
                    />
                  </div>
                ) : formData.tipo === 1 ? (
                  <div className="mb-3">
                    <label htmlFor="imageContent" className="form-label">
                      Agregar Imagen
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="imageContent"
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <label htmlFor="linkContent" className="form-label">
                      Agregar Link
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="linkContent"
                      value={formData.content}
                      onChange={(e) =>
                        setFormData({ ...formData, content: e.target.value })
                      }
                    />
                  </div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <Oval color="#fff" height={20} width={20} />
                  ) : (
                    "Agregar Novedad"
                  )}
                </button>
              </form>
            </div>
            <div className="card-body">
              <h4>Novedades Agregadas</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Contenido</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {novedades && novedades.length > 0 ? (
                    novedades.map((novedad) => (
                      <tr key={novedad.NovedadId}>
                        <td>{novedad.NovedadId}</td>
                        <td>
                          {novedad.tipo === 1 ? (
                            <img
                              src={`${window.BACKEND_URL}uploads/${novedad.content}`}
                              alt="Imagen"
                              style={{ maxWidth: "100px" }}
                            />
                          ) : novedad.tipo === 3 || novedad.tipo === 4 ? (
                            <a
                              href={novedad.content}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {novedad.content}
                            </a>
                          ) : (
                            <div
                              className="novedad-description"
                              style={{ textAlign: "justify" }}
                              dangerouslySetInnerHTML={{
                                __html: novedad.content,
                              }}
                            ></div>
                          )}
                        </td>
                        <td>
                          {novedad.tipo === 1
                            ? "Imagen"
                            : novedad.tipo === 2
                            ? "Texto"
                            : novedad.tipo === 3
                            ? "YouTube"
                            : novedad.tipo === 4
                            ? "TikTok"
                            : ""}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => handleDelete(novedad.NovedadId)}
                          >
                            Eliminar
                          </button>
                          <button
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => openModal(novedad)}
                          >
                            Editar
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No hay novedades aún.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Modal para editar novedad */}
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Editar Novedad"
      >
        <h2>Editar Novedad</h2>
        <div className="mb-3">
          <label htmlFor="contentType">
            Tipo de Contenido:
            <select
              id="contentType"
              className="form-select"
              value={formData.tipo}
              onChange={handleContentTypeChange}
            >
              <option value={2}>Texto</option>
              <option value={1}>Imagen</option>
              <option value={3}>YouTube</option>
              <option value={4}>TikTok</option>
            </select>
          </label>
        </div>
        {formData.tipo === 2 ? (
          <div className="mb-3">
            <label htmlFor="editContent">
              Contenido:
              <ReactQuill
                id="editContent"
                value={formData.content}
                onChange={handleContentChange}
              />
            </label>
          </div>
        ) : formData.tipo === 1 ? (
          <div className="mb-3">
            <label htmlFor="editContent">
              Subir Nueva Imagen:
              <input
                type="file"
                className="form-control"
                id="editContent"
                onChange={handleFileChange}
              />
            </label>
          </div>
        ) : (
          <div className="mb-3">
            <label htmlFor="editContent">
              Editar Link:
              <input
                type="text"
                className="form-control"
                id="editContent"
                value={formData.content}
                onChange={(e) =>
                  setFormData({ ...formData, content: e.target.value })
                }
              />
            </label>
          </div>
        )}
        <button
          className="btn btn-primary"
          onClick={handleEdit}
          disabled={loading}
        >
          Guardar Cambios
        </button>
        <button
          className="btn btn-secondary ms-2"
          onClick={closeModal}
          disabled={loading}
        >
          Cancelar
        </button>
      </ReactModal>
    </>
  );
};

export default AddNovedadesTorneo;
