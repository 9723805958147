import React, { useState, useEffect, useRef } from "react";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay"; // Importa el nuevo componente
import $ from "jquery";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const tablablog = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([getBlog(), getColaboradores()]);
        //initializeDataTable();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = () => {
      if (!tablablog.current || $.fn.DataTable.isDataTable(tablablog.current)) {
        return;
      }

      $(tablablog.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const getColaboradores = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}colaboradores`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
        },
      });
      setColaboradores(response.data);
    } catch (error) {
      console.error("Error fetching colaboradores:", error);
    }
  };

  const getBlog = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}blogposts`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
        },
      });
      setPosts(response.data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`${window.BACKEND_URL}blogposts/${postId}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
        },
      });
      await getBlog(); // Update the posts list after deletion
      const deletedPost = posts.find((post) => post._id === postId);
      setDeleteMessage(`Post "${deletedPost.titulo}" eliminado`);
      setShowModal(true);

      // Cerrar el modal automáticamente después de 2 segundos
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const Addpost = () => {
    navigate("/Addpost");
  };

  const handleEdit = (postId) => {
    navigate(`/Editblog/${postId}`);
  };

  return (
    <>
      <div className="position-relative">
        {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
        {!loading && (
          <div className="card">
            <div className="card-header">
              <span className="float-end">
                <button className="btn btn-primary" onClick={Addpost}>
                  <i className="fa-solid fa-plus"></i> Agregar Post
                </button>
              </span>
              <h5 className="card-title mb-0">Blogs</h5>
            </div>
            <div className="card-body">
              <table className="table table-bordered" ref={tablablog}>
                <thead>
                  <tr>
                    <th>Titulo</th>
                    <th>Autor</th>
                    <th>Categoria</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {posts.map((post) => (
                    <tr key={post._id}>
                      <td>{post.titulo}</td>
                      <td>
                        {colaboradores.find(
                          (colaborador) =>
                            colaborador._id === post.colaborador_id
                        )?.namecol || "N/A"}
                      </td>
                      <td>{post.categoria}</td>
                      <td>
                        <button
                          className="btn btn-primary me-2"
                          onClick={() => handleEdit(post._id)}
                        >
                          <BsPencilSquare /> Editar
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(post._id)}
                        >
                          <BsTrash3Fill /> Eliminar
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {showModal && (
                <div className="modal" style={{ display: "block" }}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Mensaje de Confirmación</h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowModal(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p>{deleteMessage}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Blogs;
