import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import LoadingOverlay from "../components/LoadingOverlay";

const Message = () => {
  const [messages, setMessages] = useState([]);
  const tablamessage = useRef(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const getMessages = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}messages`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });

      if (!response.data) {
        throw new Error("Error al obtener los mensajes");
      }

      setMessages(response.data);
    } catch (error) {
      console.error("Error al obtener los mensajes:", error);
      // Manejar el error, por ejemplo, mostrar un mensaje al usuario
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getMessages();
        //initializeDataTable();
      } catch (error) {
        console.error("Error al obtener los mensajes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (
        !tablamessage.current ||
        $.fn.DataTable.isDataTable(tablamessage.current)
      ) {
        return;
      }

      $(tablamessage.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Formatear la fecha como "dd/mm/yyyy" u otro formato deseado
  };

  const ViewMessage = (messageId) => {
    navigate(`/Allmessage/${messageId}`);
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Mensajes</h5>
          </div>
          <div className="card-body">
            <table className="table table-bordered" ref={tablamessage}>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Asunto</th>
                  <th>Fecha de Envío</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {messages.map((message, index) => (
                  <tr key={index}>
                    <td>{message.nombre}</td>
                    <td>{message.email}</td>
                    <td>{message.asunto}</td>
                    <td>{formatDate(message.created)}</td>
                    <td>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => ViewMessage(message._id)}
                      >
                        Ver Mensaje
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
