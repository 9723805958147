import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddCurso = () => {
  const navigate = useNavigate();

  const [curso, setCurso] = useState({
    nombre: "",
    descripcion: "",
    detalles: "",
    slug: "",
    precio: {
      alumnos: 0,
      egresadosYBachiller: 0,
      abogadosTitulados: 0,
      agremiadosCAP: 0,
      grupoDe3: 0,
      grupoDe4: 0,
    },
    fecha_in: new Date(),
    fecha_fin: new Date(),
    turno: "",
    dificultad: 0,
    banerimg: null,
    pathPortad: null,
    modalidad: "",
    dias: "",
    materia: "",
    horas: 0,
    docente_id: "",
  });
  const [loading, setLoading] = useState(false);

  const [docentes, setDocentes] = useState([]);
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    const fetchDocentes = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}users?role=Docente`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );
        const filteredDocentes = response.data.filter(
          (docente) => docente.role === "Docente"
        );
        setDocentes(filteredDocentes);
      } catch (error) {
        console.error("Error al obtener los docentes:", error);
      }
    };

    const fetchCategorias = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}categoria`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        });
        setCategorias(response.data);
      } catch (error) {
        console.error("Error al obtener las categorias:", error);
      }
    };

    fetchCategorias();
    fetchDocentes();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Obtiene el primer archivo seleccionado
    setCurso({
      ...curso,
      pathPortad: file,
      banerimg: file,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurso({ ...curso, [name]: value });
  };

  const handlePrecioChange = (e) => {
    const { name, value } = e.target;
    setCurso({
      ...curso,
      precio: {
        ...curso.precio,
        [name]: parseInt(value, 10),
      },
    });
  };

  const renderPrecioInputs = (sectionTitle, sectionFields) => {
    const labels = {
      alumnos: "Alumnos",
      egresadosYBachiller: "Egresados y Bachilleres",
      abogadosTitulados: "Abogados Titulados",
      agremiadosCAP: "Agremiados del Colegio de Abogados de Puno",
      grupoDe3: "Grupos de 3 personas",
      grupoDe4: "Grupos de 4 personas",
    };

    return (
      <div key={sectionTitle} className="mb-4">
        {" "}
        {/* Agregamos un margen debajo del título de sección */}
        <h4>{sectionTitle}</h4>
        {sectionFields.map((campo) => (
          <div key={campo} className="mb-3" style={{ marginLeft: "15px" }}>
            <label htmlFor={campo} className="form-label">
              {labels[campo]}:
            </label>
            <div>
              <input
                type="number"
                className="form-control"
                id={campo}
                name={campo}
                value={curso.precio[campo]}
                onChange={handlePrecioChange}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleChangeDate = (date) => {
    if (date && !isNaN(date.getTime())) {
      setCurso({ ...curso, fecha_in: date });
    }
  };
  const datecambio = (date) => {
    if (date && !isNaN(date.getTime())) {
      setCurso({ ...curso, fecha_fin: date });
    }
  };

  const dificultad = (event) => {
    let value = event.target.value;
    value = isNaN(value) ? 0 : parseInt(value, 10);
    value = Math.min(Math.max(value, 1), 5);
    setCurso({ ...curso, dificultad: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const formData = new FormData(e.target);
      formData.append("detalles", curso.detalles);
      await axios.post(`${window.BACKEND_URL}curso`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });
      navigate("/Curso");
    } catch (error) {
      console.error("Error al agregar el curso", error.response.data);
    } finally {
      setLoading(false);
    }
  };

  const Curso = () => {
    navigate("/Curso"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0">Agregar Curso</h3>
        </div>
        <hr />
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="nombre" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nombre"
                    name="nombre"
                    value={curso.nombre}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="descripcion" className="form-label">
                    Descripcion:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="descripcion"
                    name="descripcion"
                    value={curso.descripcion}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="slug" className="form-label">
                    Slug:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    name="slug"
                    value={curso.slug}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  {renderPrecioInputs("Costo del curso", [
                    "alumnos",
                    "egresadosYBachiller",
                    "abogadosTitulados",
                    "agremiadosCAP",
                  ])}
                  {renderPrecioInputs("Precios corporativos", [
                    "grupoDe3",
                    "grupoDe4",
                  ])}
                </div>
                <div className="mb-3">
                  <label>Autor del post:</label>
                  <select
                    type="text"
                    className="form-select"
                    name="docente_id"
                    value={curso.docente_id}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecciona un Docente:</option>
                    {docentes.map((docente) => (
                      <option key={docente._id} value={docente._id}>
                        {docente.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="dificultad" className="form-label">
                    Dificultad:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="dificultad"
                    name="dificultad"
                    value={curso.dificultad}
                    onChange={dificultad}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="turno" className="form-label">
                    Turno:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="turno"
                    name="turno"
                    value={curso.turno}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="turno" className="form-label">
                    Semanas / dias:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="turno"
                    name="dias"
                    value={curso.dias}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="horas" className="form-label">
                    Horas academicas:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="horas"
                    name="horas"
                    placeholder="ponga las hora que se va llavar el curso"
                    value={curso.horas}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* divicion del col */}

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="modalidad" className="form-label">
                    Modalidad:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="modalidad"
                    name="modalidad"
                    value={curso.modalidad}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="pathPortad" className="form-label">
                    Foto del card:
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="pathPortad"
                    name="pathPortad"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="banerimg" className="form-label">
                    foto de detalles del curso:
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="banerimg"
                    name="banerimg"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="form-group">
                  <label>Materia del curso:</label>
                  <select
                    type="text"
                    className="form-select"
                    name="materia"
                    value={curso.materia}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecciona una Materia:</option>
                    {categorias.map((categoria) => (
                      <option key={categoria.materia} value={categoria.materia}>
                        {categoria.materia}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Fecha de inicio:</label>
                  <br />
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    selected={curso.fecha_in}
                    onChange={handleChangeDate}
                    name="fecha_in"
                    type="date"
                    className="form-control"
                    icon="fa fa-calendar"
                  />
                </div>

                <div className="form-group">
                  <label>Fecha fin:</label>
                  <br />
                  <DatePicker
                    type="date"
                    dateFormat="dd-MM-yyyy"
                    selected={curso.fecha_fin}
                    name="fecha_fin"
                    onChange={datecambio}
                    className="form-control"
                    icon="fa fa-calendar"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="detalles" className="form-label">
                  Detalles:
                </label>
                <ReactQuill
                  value={curso.detalles}
                  onChange={(content) =>
                    setCurso({ ...curso, detalles: content })
                  }
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mt-2">
              <button type="submit" className="btn btn-primary" onClick={Curso}>
                <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
              </button>
              <button type="submit" className="btn btn-primary">
                {loading ? (
                  <Oval
                    color="#00BFFF"
                    height={40}
                    width={40}
                    loading={loading}
                  />
                ) : (
                  <>
                    Agregar Curso <BsPlus style={{ fontSize: "2em" }} />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCurso;
