import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import axios from "axios";
import ReactQuill from "react-quill";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from "react-loader-spinner";

const Editblog = () => {
  const [formData, setFormData] = useState({
    publicado: true,
    titulo: "",
    tags: "",
    defoto: "",
    categoria: "",
    colaborador_id: "",
    datepost: null,
    autor2: "",
    sentencia: "",
    expediente: "",
    lugar: "",
    summary: "",
    PDFile: null,
    postcard: null,
    imagen: null,
    body: "",
  });
  const navigate = useNavigate();
  const [colaboradores, setColaboradores] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}blogposts/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );
        const postData = response.data;
        setFormData(postData);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    const fetchColaboradores = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}colaboradores`);
        const colaboradoresData = response.data;
        setColaboradores(colaboradoresData);
      } catch (error) {
        console.error("Error fetching colaboradores:", error);
      }
    };

    fetchPost();
    fetchColaboradores();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value, type, checked, files } = event.target;

    if (type === "file") {
      // Verificar si hay un archivo seleccionado
      if (files.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: files[0], // Asigna el archivo directamente
        }));
      } else {
        // Mantener el mismo nombre de archivo si no se selecciona uno nuevo
        setFormData((prevData) => ({
          ...prevData,
          [name]: prevData[name], // Mantener el valor actual
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleChangeDate = (date) => {
    setFormData({ ...formData, datepost: date });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const postData = new FormData();
    postData.append("publicado", formData.publicado);
    postData.append("titulo", formData.titulo);
    postData.append("tags", formData.tags);
    postData.append("defoto", formData.defoto);
    postData.append("categoria", formData.categoria);
    postData.append("colaborador_id", formData.colaborador_id);
    postData.append("datepost", formData.datepost);
    postData.append("autor2", formData.autor2);
    postData.append("sentencia", formData.sentencia);
    postData.append("expediente", formData.expediente);
    postData.append("lugar", formData.lugar);
    postData.append("summary", formData.summary);
    postData.append("body", formData.body);

    if (formData.PDFile) {
      postData.append("PDFile", formData.PDFile);
    }
    if (formData.postcard) {
      postData.append("postcard", formData.postcard);
    }
    if (formData.imagen) {
      postData.append("imagen", formData.imagen);
    }
    console.log(formData);
    try {
      const response = await axios.put(
        `${window.BACKEND_URL}blogposts/${id}`,
        postData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Respuesta del servidor:", response.data);
        navigate("/Blogs");
      } else {
        console.error("Error en la solicitud:", response.statusText);
      }
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReturn = () => {
    navigate("/Blogs");
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Editar Post</h3>
            </div>
            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="publicado"
                        checked={formData.publicado}
                        onChange={handleInputChange}
                      />
                      <label>Publicado</label>
                    </div>
                    <div className="form-group">
                      <label>Título:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="titulo"
                        value={formData.titulo}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Tags:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="tags"
                        value={formData.tags}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Derecho de la foto:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="defoto"
                        value={formData.defoto}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Materia del post:</label>
                      <select
                        className="form-select"
                        name="categoria"
                        value={formData.categoria}
                        onChange={handleInputChange}
                      >
                        <option value="">Selecciona una categoría</option>
                        <option value="Penal">Penal</option>
                        <option value="Civil">Civil</option>
                        <option value="Constitucional">Constitucional</option>
                        <option value="Registral">Registral</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Autor del post:</label>
                      <select
                        className="form-select"
                        name="colaborador_id"
                        value={formData.colaborador_id}
                        onChange={handleInputChange}
                      >
                        <option value="">Selecciona un Autor</option>
                        {colaboradores.map((colaborador) => (
                          <option key={colaborador._id} value={colaborador._id}>
                            {colaborador.namecol}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Fecha del post:</label>
                      <br />
                      <DatePicker
                        dateFormat="dd-MM-yyyy"
                        showIcon
                        selected={
                          formData.datepost ? new Date(formData.datepost) : null
                        }
                        onChange={handleChangeDate}
                        className="form-control"
                        icon="fa fa-calendar"
                      />
                    </div>
                    <div className="form-group">
                      <label>Sentencia:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="sentencia"
                        value={formData.sentencia}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Expediente:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="expediente"
                        value={formData.expediente}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Lugar:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lugar"
                        value={formData.lugar}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Resumen del card:</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="summary"
                        value={formData.summary}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>URL del archivo PDF:</label>
                      <br />
                      <input
                        type="file"
                        className="form-control"
                        name="PDFile"
                        onChange={handleInputChange}
                        accept="application/pdf"
                      />
                      {formData.PDFile && <embed width="400" height="275" />}
                    </div>
                    <div className="form-group">
                      <label>Portada del card:</label>
                      <br />
                      <input
                        type="file"
                        className="form-control"
                        name="postcard"
                        onChange={handleInputChange}
                        accept="image/*"
                      />
                      {formData.postcard && (
                        <img
                          style={{ width: "50%" }}
                          alt="Imagen de post"
                          width="200"
                          height="175"
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label>Imagen:</label>
                      <br />
                      <input
                        type="file"
                        className="form-control"
                        name="imagen"
                        onChange={handleInputChange}
                        accept="image/*"
                      />
                      {formData.imagen && (
                        <img
                          style={{ width: "20%" }}
                          alt="Portada"
                          width="200"
                          height="175"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Cuerpo del documento:</label>
                  <ReactQuill
                    value={formData.body}
                    onChange={(content) =>
                      setFormData({ ...formData, body: content })
                    }
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleReturn}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <>
                        <Oval color="#00BFFF" height={40} width={40} />{" "}
                      </>
                    ) : (
                      <>
                        <BsPencilSquare /> Editar Blog
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editblog;
