import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import LoadingOverlay from "../components/LoadingOverlay"; // Importa el nuevo componente

const URI = `${window.BACKEND_URL}users`;

const Users = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getUsers();
        //initializeDataTable();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tableRef.current || $.fn.DataTable.isDataTable(tableRef.current)) {
        return;
      }

      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };
    initializeDataTable();
  }, [loading]);

  const handleAddUser = () => {
    navigate("/Addusers"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  const getUsers = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!URI || token === null) {
        throw new Error("URI no definido o token nulo");
      }

      const response = await fetch(URI, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }

      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error al obtener los usuarios:", error);
    }
  };

  const handleEdit = (userId) => {
    navigate(`/Editusers/${userId}`);
  };

  const editpass = (userId) => {
    navigate(`/Editpassword/${userId}`);
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="card">
          <div className="card-header">
            <span className="float-end">
              <button className="btn btn-primary" onClick={handleAddUser}>
                <i className="fa-solid fa-plus"></i> Agregar Usuario
              </button>
            </span>
            <h3 className="card-title mb-0">Usuarios</h3>
          </div>
          <div className="card-body">
            <table className="table table-bordered" ref={tableRef}>
              <thead>
                <tr>
                  <th>Foto</th>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Rol</th> {/*Los roles solo van a ser Admin y Docente*/}
                  <th>Estado</th>
                  <th>Contraseña</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td>
                      {user.photo && (
                        <img
                          src={`${URI}uploads/${user.photo}`}
                          alt={`Foto de ${user.name}`}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={user.estado}
                        disabled={!user.estado}
                      />
                    </td>
                    <td>{user.password ? "•••••••••••••••••" : ""}</td>
                    <td>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => handleEdit(user._id)}
                      >
                        <BsPencilSquare /> Editar
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => editpass(user._id)}
                      >
                        <BsTrash3Fill /> Contraseña
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
