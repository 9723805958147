import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import LoadingOverlay from "../components/LoadingOverlay";
import Moment from "react-moment";
import $ from "jquery";
import axios from "axios";

const Tareas = () => {
  const [tareas, setTareas] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const tareastabla = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getTareas();
        initializeDataTable();
      } catch (error) {
        console.error("Error al obtener las tareas:", error);
      } finally {
        setLoading(false);
      }
    };

    const initializeDataTable = () => {
      if (
        !tareastabla.current ||
        !$.fn.DataTable.isDataTable(tareastabla.current)
      ) {
        $(tareastabla.current).DataTable({
          language: {
            url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
          },
        });
      }
    };

    fetchData();
  }, []);

  const getTareas = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}tareas`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": sessionStorage.getItem("role"),
        },
      });
      setTareas(response.data);
    } catch (error) {
      console.error("Error al obtener las tareas:", error);
    }
  };

  const eliminarTarea = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}tareas/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": sessionStorage.getItem("role"),
        },
      });
      setTareas(tareas.filter((tarea) => tarea._id !== id));
    } catch (error) {
      console.error("Error al eliminar la tarea:", error);
    }
  };

  const Edittarea = (TareaId) => {
    navigate(`/Edittareas/${TareaId}`);
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Tareas</h5>
          </div>
          <div className="card-body">
            <table className="table table-bordered" ref={tareastabla}>
              <thead>
                <tr>
                  <th>Alumno</th>
                  <th>Curso</th>
                  <th>Nota</th>
                  <th>Path</th>
                  <th>Fecha</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {tareas.map((tarea) => (
                  <tr key={tarea._id}>
                    <td>{tarea.informacion_alumno[0]?.nombre}</td>
                    <td>{tarea.informacion_curso[0]?.nombre}</td>
                    <td>{tarea.nota ? tarea.nota : "(No calificado)"}</td>
                    <td>{tarea.path}</td>
                    <td>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Fecha:</strong>{" "}
                              <Moment format="DD-MM-YYYY">
                                {tarea.created}
                              </Moment>
                            </td>
                            <td>
                              <strong>Hora:</strong>{" "}
                              <Moment format="hh:mm:ss A">
                                {tarea.created}
                              </Moment>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                    <td>
                      {/* <button
                        className="btn btn-primary me-2"
                        onClick={() => Edittarea(tarea._id)}
                      >
                        <BsPencilSquare /> Editar
                      </button> */}
                      <button
                        className="btn btn-danger"
                        onClick={() => eliminarTarea(tarea._id)}
                      >
                        <BsTrash3Fill /> Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tareas;
