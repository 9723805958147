import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import LoadingOverlay from "../components/LoadingOverlay";
import * as XLSX from "xlsx";

const Pre_inscripcion = () => {
  const [messages, setMessages] = useState([]);
  const [courses, setCourses] = useState([]);
  const tablamessage = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState("all");

  const getMessages = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}inscripcion`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
          "X-Rol": sessionStorage.getItem("role"),
        },
      });
      setMessages(response.data);

      const uniqueCourses = [...new Set(response.data.map((msg) => msg.curso))];
      setCourses(uniqueCourses);
    } catch (error) {
      console.error("Error al obtener los inscritos:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getMessages();
      } catch (error) {
        console.error("Error al obtener los inscritos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tablamessage.current || !$.fn.DataTable) return;

      $(tablamessage.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleDownloadExcel = () => {
    const filteredMessages =
      selectedCourse === "all"
        ? messages
        : messages.filter((message) => message.curso === selectedCourse);

    const data = filteredMessages.map((message) => ({
      Curso: message.curso,
      "Nombres y apellidos": `${message.nombre} ${message.apellidos}`,
      Email: message.email,
      Telefono: message.telefono,
      DNI: message.dni,
      Universidad: message.universidad[0]?.Nombre,
      Colegiado: message.colegio[0]?.Nombre
        ? message.colegio[0]?.Nombre
        : "No colegiado",
      "Fecha de Envío": formatDate(message.created),
      "Recibir correos": message.notificaciones ? "sí" : "no",
      Declaracion: message.inscripcion ? "sí" : "no",
      Datos: message.certificado ? "sí" : "no",
    }));

    const ws = XLSX.utils.json_to_sheet(data);
    ws["!autofilter"] = { ref: "A1:I1" };
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Usuarios");
    XLSX.writeFile(wb, "usuarios.xlsx");
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="card">
          <div className="card-header">
            <span className="float-end">
              <select
                className="form-select"
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
              >
                <option value="all">Todos los cursos</option>
                {courses.map((course, index) => (
                  <option key={index} value={course}>
                    {course}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-primary ms-2"
                disabled={messages.length === 0}
                onClick={handleDownloadExcel}
              >
                <i className="fa-solid fa-download"></i> Descargar Excel
              </button>
            </span>
            <h3 className="card-title mb-0">Pre Inscritos</h3>
          </div>
          <div className="card-body">
            <table className="table table-bordered" ref={tablamessage}>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Asunto</th>
                </tr>
              </thead>
              <tbody>
                {messages.map((message, index) => (
                  <tr key={index}>
                    <td>{message.nombre}</td>
                    <td>{message.email}</td>
                    <td>{message.curso}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pre_inscripcion;
