import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const EditUniversidad = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    Nombre: "",
    photo: null, // Initially null as we're fetching it from the server
  });
  const [preview1, setPreview1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const getUniversidad = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}universidades/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "X-Rol": sessionStorage.getItem("role"),
            },
          }
        );
        setFormData(response.data);
        setPreview1(response.data.photo);
      } catch (error) {
        console.error("Error al obtener la universidad:", error);
      }
    };
    getUniversidad();
  }, [id]);

  const handlePhotoChange1 = (e) => {
    const selectedPhoto = e.target.files[0];
    if (selectedPhoto) {
      setFormData({
        ...formData,
        photo: selectedPhoto,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview1(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("Nombre", formData.Nombre);
      if (formData.photo) {
        formDataToSend.append("photo", formData.photo);
      }
      const response = await axios.put(
        `${window.BACKEND_URL}universidades/${id}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      if (response.status === 200) {
        alert("Universidad actualizada correctamente");
        navigate("/Universidad");
      }
    } catch (error) {
      console.error("Error al actualizar la universidad:", error);
      alert(
        "Error al actualizar la universidad. Revisa la consola para más detalles."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/Universidad");
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Universidad</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="Nombre" className="form-label">
                      Nombre de la universidad:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Nombre"
                      name="Nombre"
                      value={formData.Nombre}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="photo" className="form-label">
                      Foto:
                    </label>
                    <input
                      name="photo"
                      type="file"
                      className="form-control"
                      id="photo"
                      onChange={handlePhotoChange1}
                    />
                  </div>
                  {preview1 && (
                    <img
                      src={`${window.BACKEND_URL}uploads/${preview1}`}
                      alt="Preview"
                      style={{ maxWidth: "50%", height: "auto" }}
                    />
                  )}
                  {error && <div className="text-danger">{error}</div>}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBack}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={50}
                      width={50}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Editar Universidad <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUniversidad;
