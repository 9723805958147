import { Outlet, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LayoutAdmin from "../components/LayoutAdmin";
import LayoutDocente from "../components/LayoutDocente";

const Layout = () => {
  const [username] = useState(sessionStorage.getItem("userName"));
  const [role] = useState(sessionStorage.getItem("role"));

  const [rol, setRol] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  //console.log("Login", sessionStorage);

  useEffect(() => {
    if (role === "Admin") {
      setRol(true);
    } else {
      setRol(false);
    }
  }, [role]);

  return (
    <>
      <nav id="sidebar" className="sidebar">
        {rol ? <LayoutAdmin /> : <LayoutDocente />}
      </nav>
      <div className="main">
        <nav className="navbar navbar-expand navbar-light navbar-bg ">
          <a className="sidebar-toggle">
            <i className="hamburger align-self-center"></i>
          </a>
          <div className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align">
              <li className="nav-item dropdown">
                <a
                  className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <i className="align-middle" data-feather="settings"></i>
                </a>
                <a
                  className="nav-link dropdown-toggle d-none d-sm-inline-block"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src="img/avatars/avatar.jpg"
                    className="avatar img-fluid rounded-circle me-1"
                    alt="Chris Wood"
                  />
                  <span className="text-dark">{username}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="pages-profile.html">
                    <i className="align-middle me-1" data-feather="user"></i>{" "}
                    Profile
                  </a>
                  <a className="dropdown-item" href="#">
                    <i
                      className="align-middle me-1"
                      data-feather="pie-chart"
                    ></i>{" "}
                    Analytics
                  </a>

                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="pages-settings.html">
                    Settings & Privacy
                  </a>
                  <a className="dropdown-item" href="#">
                    Help
                  </a>
                  <span className="dropdown-item" onClick={handleLogout}>
                    Sign out
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        <main className="content">
          <div className="container-fluid p-0">
            <Outlet />
          </div>
        </main>

        <footer className="footer">
          <div className="container-fluid">
            <div className="row text-muted">
              <div className="col-6 text-start">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a className="text-muted" href="#">
                      Support
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-6 text-end">
                <p className="mb-0">
                  &copy; 2024 -{" "}
                  <a className="text-muted" href="index.html">
                    Stemis
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
