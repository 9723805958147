import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const API_URL = window.BACKEND_URL;

const Editnormativapdf = () => {
  const [pdf, setPdf] = useState("");
  const [file, setFile] = useState(null);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getPdf = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}normatividad/${id}/pdf`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
           "Content-Type": "multipart/form-data",
          "X-Rol": sessionStorage.getItem("role"),
        },
      });
      setPdf(response.data);
    } catch (error) {
      console.error("Error fetching pdf:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getPdf(); // Cambiado de "getPortadas" a "getPdf"
      } catch (error) {
        console.error("Error al obtener el pdf:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Guardar el archivo seleccionado
  };

  const handleInputChange = (e) => {
    setPdf(e.target.value); // Cambiado de "portada" a "pdf"
  };

  const handleSubmit = async (event) => {
    console.log("Pdfsss: ", pdf);
    event.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("archivopdf", file); // Cambiado de "portada" a "pdf"
      //console.log(file)

      await axios.put(`${API_URL}normatividad/${id}/pdf`, formData, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
          "X-Rol": sessionStorage.getItem("role"),
        },
      });

      console.log("pdf actualizado exitosamente"); // Cambiado de "Portada" a "pdf"
      navigate("/Normativa");
    } catch (error) {
      console.error("Error al actualizar el pdf:", error); // Cambiado de "Portada" a "pdf"
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="pdf">
          <div className="pdf-header">
            <h3 className="pdf-title mb-0">Editar pdf</h3>
          </div>
          <div className="pdf-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>pdf</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{pdf}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pdf-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Seleccionar Archivo:</label>
                    <input
                      type="file"
                      name="foto_Pdf"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <Link className="btn btn-primary" to="/Normativa">
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Actualizar pdf <BsPlus style={{ fontSize: "2em" }} />{" "}
                      {/* Cambiado de "Portada" a "pdf" */}
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editnormativapdf;
