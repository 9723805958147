import React, { useState } from "react";
import axios from "axios"; // O puedes usar fetch
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const AddAlumno = () => {
  const navigate = useNavigate();

  const [alumno, setAlumno] = useState({
    Nombre: "",
    Correo: "",
    Dni: "",
    Celular: "",
    Contrasena: "",
    validarEmail: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim(); // Eliminar espacios en blanco al principio y al final
    setAlumno({ ...alumno, [name]: trimmedValue });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      await axios.post(`${window.BACKEND_URL}alumno`, alumno, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
        },
      });

      navigate("/Alumno");
    } catch (error) {
      console.error("Error al agregar el alumno", error.response.data);
      // Manejo de errores (mostrar mensaje al usuario, etc.)
    } finally {
      setLoading(false);
    }
  };

  const AlumnoList = () => {
    navigate("/Alumno"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Agregar Alumno</h3>
            </div>
            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="nombre" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Nombre"
                    name="Nombre"
                    value={alumno.Nombre}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="dni" className="form-label">
                    DNI:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="Dni"
                    name="Dni"
                    value={alumno.Dni}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Correo"
                    name="Correo"
                    value={alumno.Correo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="validarEmail" className="form-label">
                    Validar Email:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="validarEmail"
                    name="validarEmail"
                    value={alumno.validarEmail}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="Contrasena"
                    name="Contrasena"
                    value={alumno.Contrasena}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={AlumnoList}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <Oval
                        color="#00BFFF"
                        height={40}
                        width={40}
                        loading={loading}
                      />
                    ) : (
                      <>
                        Agregar Alumno <BsPlus style={{ fontSize: "2em" }} />
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAlumno;
