import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from "react-loader-spinner";

const EditFactura = () => {
  const [factura, setFactura] = useState({
    fecha: null,
    serienumero: '',
    cliente: '',
    descripcion: '',
    monto: 0,
    tipo: ''
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchFactura = async () => {
    try {
      const response = await fetch(`https://backendhomepage.stemis.com.pe/facturas/${id}`);
      if (!response.ok) {
        throw new Error('Error al obtener la factura');
      }
      const facturaData = await response.json();
      setFactura(facturaData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchFactura();
  }, [id]);

  const handleInputChange = (e) => {
    setFactura({ ...factura, [e.target.name]: e.target.value });
  };

  const handleChangeDate = (date) => {
    if (date) {
      setFactura({ ...factura, fecha: date });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(`http://localhost:3001/facturas/${id}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(factura),
      });
 
      if (response.ok) {
        navigate('/Facturas');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(true);
    }
  };
  const Facturas = () => {
    navigate('/Facturas'); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">

          <div className="card-header">
            <h3 className="card-title mb-0">Editar Factura</h3>
          </div>
          <hr />
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="fecha" className="form-label">Fecha:</label>
                <br />
                <DatePicker
                    dateFormat="dd-MM-yyyy"
                    showIcon
                    selected={new Date(factura.fecha)}
                    onChange={handleChangeDate}
                    className="form-control"
                    icon="fa fa-calendar"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="serienumero" className="form-label">Serie Número:</label>
                <input
                  type="text"
                  className="form-control"
                  id="serienumero"
                  name="serienumero"
                  value={factura.serienumero}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="cliente" className="form-label">Cliente:</label>
                <input
                  type="text"
                  className="form-control"
                  id="cliente"
                  name="cliente"
                  value={factura.cliente}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="descripcion" className="form-label">Descripción:</label>
                <textarea
                  className="form-control"
                  id="descripcion"
                  name="descripcion"
                  value={factura.descripcion}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="monto" className="form-label">Monto:</label>
                <input
                  type="number"
                  className="form-control"
                  id="monto"
                  name="monto"
                  value={factura.monto}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="tipo" className="form-label">Tipo:</label>
                <select
                  className="form-select"
                  id="tipo"
                  name="tipo"
                  value={factura.tipo}
                  onChange={handleInputChange}
                >
                  <option value="Venta">Venta</option>
                  <option value="Compra">Compra</option>
                  <option value="Gasto">Gastos</option>
                </select>
              </div>
              <div className="d-flex justify-content-between">
                <button type="submit" className="btn btn-primary" onClick={Facturas}>
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                      <>
                        <Oval color="#00BFFF" height={40} width={40} />                      </>
                    ) : (
                      <>
                        <BsPencilSquare /> Editar Factura
                      </>
                    )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFactura;
