import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsCheck } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const EditBaner = () => {
  const [descripcion, setDescripcion] = useState("");
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams(); // Obtener el ID del banner desde los parámetros de la URL

  useEffect(() => {
    // Cargar los datos del banner existente
    const fetchBaner = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}baner/${id}`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
          },
        });
        const { descripcion, photo } = response.data;
        setDescripcion(descripcion);
        setPreview(`${window.BACKEND_URL}uploads/${photo}`);
      } catch (error) {
        console.error("Error al cargar el banner:", error);
      }
    };

    fetchBaner();
  }, [id]);

  const handlePhotoChange = (e) => {
    const selectedPhoto = e.target.files[0];
    setPhoto(selectedPhoto);
    if (selectedPhoto) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("descripcion", descripcion);
      if (photo) {
        formData.append("photo", photo);
      }

      const response = await axios.put(
        `${window.BACKEND_URL}baner/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`, 
          },
        }
      );

      if (response.status === 200) {
        alert("Banner actualizado correctamente");
        navigate("/baner");
      }
    } catch (error) {
      console.error("Error al actualizar el banner:", error);
      alert("Error al actualizar el banner");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/baner");
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Banner</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="descripcion" className="form-label">
                  Link:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="descripcion"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="photo" className="form-label">
                  Foto:
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="photo"
                  onChange={handlePhotoChange}
                />
              </div>
              {preview && (
                <img
                  src={preview}
                  alt="Preview"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              )}
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBack}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={50}
                      width={50}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Actualizar Banner <BsCheck style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBaner;
