import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const API_URL = window.BACKEND_URL;

const Editnormativaimagen = () => {
  const [imagen, setImagen] = useState(""); // Cambiado de "portada" a "imagen"
  const [file, setFile] = useState(null); // Nuevo estado para el archivo
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getImagen = async () => {
    // Cambiado de "getPortadas" a "getImagen"
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}normatividad/${id}/imagen`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
          "X-Rol": sessionStorage.getItem("role"),
        },
      });
      setImagen(response.data);
    } catch (error) {
      console.error("Error fetching imagen:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getImagen(); // Cambiado de "getPortadas" a "getImagen"
      } catch (error) {
        console.error("Error al obtener el imagen:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Guardar el archivo seleccionado
  };

  const handleInputChange = (e) => {
    setImagen(e.target.value); // Cambiado de "portada" a "imagen"
  };

  const handleSubmit = async (event) => {
    console.log("Imagen: ", imagen);
    event.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("foto_port", file);
    

      await axios.put(`${API_URL}normatividad/${id}/imagen`, formData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
          "X-Rol": sessionStorage.getItem("role"),
        },
      });

      console.log("imagen actualizado exitosamente");
      navigate("/Normativa");
    } catch (error) {
      console.error("Error al actualizar el imagen:", error); 
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="imagen">
          <div className="imagen-header">
            <h3 className="imagen-title mb-0">Editar imagen</h3>
          </div>
          <div className="imagen-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>imagen</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{imagen}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="imagen-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Seleccionar Archivo:</label>
                    <input
                      type="file"
                      name="foto_Imagen"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <Link className="btn btn-primary" to="/Normativa">
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Actualizar imagen <BsPlus style={{ fontSize: "2em" }} />{" "}
                      {/* Cambiado de "Portada" a "imagen" */}
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editnormativaimagen;
