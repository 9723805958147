import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import Moment from "react-moment";
import LoadingOverlay from "../components/LoadingOverlay"; // Importa el nuevo componente
import axios from "axios";
import $ from "jquery";

const Cuenta = () => {
  const [cuentas, setGutCuenta] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [loading, setLoading] = useState(true);

  // monto total
  const [monto, setMonto] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Getcuenta();
        initializeDataTable();
      } catch (error) {
        console.error("Error al cargar las cuentas corrientes:", error);
      } finally {
        setLoading(false);
      }
    };

    const initializeDataTable = async () => {
      if (!tablacuen.current || $.fn.DataTable.isDataTable(tablacuen.current))
        return;

      await Getcuenta();

      $(tablacuen.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const total = cuentas.reduce((accumulator, cuenta) => {
      return cuenta.tipo === "Ingreso"
        ? accumulator + cuenta.monto
        : accumulator - cuenta.monto;
    }, 0);

    setMonto(total);
  }, [cuentas]);

  const tablacuen = useRef(null);
  const Getcuenta = async () => {
    try {
      const respuesta = await fetch(`${window.BACKEND_URL}cuentacorriente`); // Ajusta la URL según sea necesario
      if (!respuesta.ok) {
        throw new Error("Error al cargar los datos");
      }
      const datos = await respuesta.json();
      setGutCuenta(datos); // Cambia el nombre de la constante a gutcuenta
    } catch (error) {
      console.error("Error al cargar las cuentas corrientes:", error);
    }
  };
  const Editcuenta = (ceuntaId) => {
    navigate(`/Editcuenta/${ceuntaId}`);
  };
  const Addcuenta = () => {
    navigate("/Addcuenta"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}/cuentacorriente/${id}`);
      const deletedCuenta = cuentas.find((cuenta) => cuenta._id === id);
      setDeleteMessage(`Cuenta "${deletedCuenta.descripcion}" eliminada`);
      setShowModal(true);
      setGutCuenta(cuentas.filter((cuenta) => cuenta._id !== id));

      // Cerrar el modal automáticamente después de 2 segundos
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    } catch (error) {
      console.error("Error al eliminar la cuenta corriente", error);
    }
  };
  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="card">
          <div className="card-header">
            <span className="float-end">
              <button className="btn btn-primary" onClick={Addcuenta}>
                <i className="fa-solid fa-plus"></i> Agregar Cuenta Corriente
              </button>
            </span>
            <h5 className="card-title mb-0">Cuentas Corrientes</h5>
            <div className="col d-flex align-items-end">
              <p>Monto Total: {monto}</p>
            </div>
          </div>
          <div className="card-body">
            <table className="table table-bordered" ref={tablacuen}>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Periodo</th>
                  <th>Descripción</th>
                  <th>Tipo</th>
                  <th>Monto</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cuentas.map((cuenta) => (
                  <tr key={cuenta._id}>
                    <td>
                      <Moment format="DD/MM/YYYY">{cuenta.fecha}</Moment>
                    </td>
                    <td>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Mes:</strong>{" "}
                              <Moment format="MM">{cuenta.fecha}</Moment>
                            </td>
                            <td>
                              <strong>Año:</strong>{" "}
                              <Moment format="YYYY">{cuenta.fecha}</Moment>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>{cuenta.descripcion}</td>
                    <td>{cuenta.tipo}</td>
                    <td className="alinear-derecha">
                      {cuenta.monto.toFixed(2)}
                    </td>
                    <td>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => Editcuenta(cuenta._id)}
                      >
                        <BsPencilSquare /> Editar
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(cuenta._id)}
                      >
                        <BsTrash3Fill /> Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showModal && (
              <div className="modal" style={{ display: "block" }}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Mensaje de Confirmación</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>{deleteMessage}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Cuenta;
