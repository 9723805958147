import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner"; // Importa una animación de carga de tu elección

function Addusers() {
  const [isEnabled, setIsEnabled] = useState(true); // Valor inicial como habilitado
  const handleCheckboxChange = (e) => {
    setIsEnabled(e.target.checked);
  };
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    photo: null,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Nuevo estado para la animación

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewUser({ ...newUser, photo: file });
  };

  const users = () => {
    navigate("/users"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const dataToSend = {
        ...newUser,
        isEnabled: isEnabled ? 1 : 0,
      };

      const formData = new FormData();
      Object.entries(dataToSend).forEach(([key, value]) => {
        formData.append(key, value);
      });

      console.log(formData); // Log FormData to check if photo is correctly set

      const response = await fetch(`${window.BACKEND_URL}users`, {
        method: "POST",
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
        },  
        body: formData,
      });

      if (response.ok) {
        navigate("/users");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar Usuario</h3>
          </div>
          <hr />
          <div className="card-body">
            <form class="p-3" onSubmit={handleSubmit}>
              <div className="mb-3">
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="isEnabled"
                    checked={isEnabled}
                    onChange={handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor="isEnabled">
                    Habilitado
                  </label>
                </div>
                <div className="mb-3">
                  <label htmlFor="photo" className="form-label">
                    Foto
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="photo"
                    name="photo"
                    onChange={handleFileChange}
                  />
                </div>
                <label htmlFor="name" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={newUser.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={newUser.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Contraseña
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={newUser.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Rol
                </label>
                <select
                  className="form-select"
                  id="role"
                  name="role"
                  value={newUser.role}
                  onChange={handleInputChange}
                  required
                >
                  <option disabled value="">
                    Seleccione un rol
                  </option>
                  <option value="Docente">Docente</option>
                  <option value="Admin">Admin</option>
                </select>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={users}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Usuario <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addusers;
