import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const EditAlumno = () => {
  const [alumno, setAlumno] = useState({
    Nombre: "",

    Dni: 0,
    Correo: "",
    validarEmail: "",
    Contrasena: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAlumno = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}alumno/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        });

        if (!response.ok) {
          throw new Error("Error al obtener el alumno");
        }
        const alumnoData = await response.json();
        setAlumno(alumnoData);
      } catch (error) {
        console.error("Error al obtener el alumno:", error);
        // Manejar el error, por ejemplo, mostrar un mensaje al usuario
      }
    };

    fetchAlumno();

    // Limpiar efecto si es necesario
    return () => {
      // Agregar código de limpieza si es necesario, por ejemplo, cancelar la solicitud
    };
  }, [id]);

  const handleInputChange = (e) => {
    setAlumno({ ...alumno, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(`${window.BACKEND_URL}alumno/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
        },
        body: JSON.stringify(alumno),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error("Error al actualizar el alumno:", errorMessage);
      } else {
        navigate("/Alumno"); // Redireccionar después de la actualización exitosa
      }
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    } finally {
      setLoading(false);
    }
  };

  const AlumnoList = () => {
    navigate("/Alumno"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Editar Alumno</h3>
            </div>

            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="Nombre" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Nombre"
                    name="Nombre"
                    value={alumno.Nombre}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="Dni" className="form-label">
                    DNI:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="Dni"
                    name="Dni"
                    value={alumno.Dni}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Correo" className="form-label">
                    Email:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Correo"
                    name="Correo"
                    value={alumno.Correo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="validarEmail" className="form-label">
                    Validar Email:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="validarEmail"
                    name="validarEmail"
                    value={alumno.validarEmail}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Contrasena"
                    name="Contrasena"
                    placeholder="Dejar en blanco para mantener la contraseña actual"
                    onChange={handleInputChange}
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={AlumnoList}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <>
                        <Oval color="#00BFFF" height={40} width={40} />
                      </>
                    ) : (
                      <>
                        <BsPencilSquare /> Editar Alumno
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAlumno;
