import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import $ from "jquery"; // Importa jQuery para usar con Select2
import "select2/dist/css/select2.min.css"; // Importa los estilos de Select2
import "select2/dist/js/select2.min.js"; // Importa el script de Select2

const AddMatricula = () => {
  const navigate = useNavigate();

  const [matricula, setMatricula] = useState({
    alumno_id: "",
    curso_id: "",
  });

  const [alumnos, setAlumnos] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAlumnos = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}alumno`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        });
        setAlumnos(response.data);
      } catch (error) {
        console.error("Error al obtener los alumnos:", error);
      }
    };

    const fetchCursos = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}curso/listo/todo  `,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );
        setCursos(response.data);
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
      }
    };

    fetchAlumnos();
    fetchCursos();
  }, []);

  useEffect(() => {
    // Inicializa Select2 en el select de Alumno
    $("#alumnoSelect")
      .select2()
      .on("change", function (e) {
        const alumnoId = e.target.value;
        setMatricula((prevMatricula) => ({
          ...prevMatricula,
          alumno_id: alumnoId,
        }));
      });

    // Inicializa Select2 en el select de Curso
    $("#cursoSelect")
      .select2()
      .on("change", function (e) {
        const cursoId = e.target.value;
        setMatricula((prevMatricula) => ({
          ...prevMatricula,
          curso_id: cursoId,
        }));
      });
  }, []); // Asegúrate de incluir alumnos y cursos en la lista de dependencias para que Select2 se reinicialice cuando cambien

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMatricula({ ...matricula, [name]: value });
  };

  const handleAlumnoChange = (e) => {
    const alumnoId = e.target.value;
    setMatricula((prevMatricula) => ({
      ...prevMatricula,
      alumno_id: alumnoId,
    }));
  };

  const handleCursoChange = (e) => {
    const cursoId = e.target.value;
    setMatricula((prevMatricula) => ({ ...prevMatricula, curso_id: cursoId }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axios.post(`${window.BACKEND_URL}matricula`, matricula, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });

     
      navigate("/Matricula");
    } catch (error) {
      console.error("Error al agregar la matrícula", error.response.data);
      // Manejo de errores (mostrar mensaje al usuario, etc.)
    } finally {
      setLoading(false);
    }
  };

  const Matricula = () => {
    navigate("/Matricula"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Agregar Matrícula</h3>
            </div>
            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>Alumno:</label>
                  <select
                    id="alumnoSelect"
                    className="form-select"
                    name="alumno_id"
                    value={matricula.alumno_id}
                    onChange={handleAlumnoChange}
                  >
                    <option disabled value="">
                      Selecciona un alumno
                    </option>
                    {alumnos.map((alumno) => (
                      <option key={alumno._id} value={alumno._id}>
                        {alumno.Nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label>Curso:</label>
                  <select
                    id="cursoSelect"
                    className="form-select"
                    name="curso_id"
                    value={matricula.curso_id}
                    onChange={handleCursoChange}
                  >
                    <option disabled value="">
                      Selecciona un curso
                    </option>
                    {cursos.map((curso) => (
                      <option key={curso._id} value={curso._id}>
                        {curso.nombre}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={Matricula}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <Oval
                        color="#00BFFF"
                        height={40}
                        width={40}
                        loading={loading}
                      />
                    ) : (
                      <>
                        Agregar Matrícula <BsPlus style={{ fontSize: "2em" }} />
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMatricula;
