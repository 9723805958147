import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Editwebinar = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setWebinar] = useState({});
  const [preview1, setPreview1] = useState(null);
  const [preview2, setPreview2] = useState(null);
  const [preview3, setPreview3] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const getWebinar = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}Webinario/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "X-Rol": sessionStorage.getItem("role"),
            },
          }
        );
        setWebinar(response.data);
        console.log(response.data, " editar webinar datos ");
        setPreview1(response.data.photo);
        setPreview2(response.data.photo2);
        setPreview3(response.data.photo3);
      } catch (error) {
        console.error("Error al obtener los Webinarios:", error);
      } finally {
        setLoading(false);
      }
    };
    getWebinar();
  }, [id]);
  console.log(formData, "webinar ");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWebinar({
      ...formData,
      [name]: value,
    });
  };

  const handleFechaFinChange = (date) => {
    setWebinar({
      ...formData,
      fechaFin: date,
    });
  };

  const handlePhotoChange1 = (e) => {
    const selectedPhoto = e.target.files[0];
    if (selectedPhoto) {
      setWebinar({
        ...formData,
        photo: selectedPhoto,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview1(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    }
  };

  const handlePhotoChange2 = (e) => {
    const selectedPhoto = e.target.files[0];
    if (selectedPhoto) {
      setWebinar({
        ...formData,
        photo2: selectedPhoto,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview2(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    }
  };

  const handlePhotoChange3 = (e) => {
    const selectedPhoto = e.target.files[0];
    if (selectedPhoto) {
      setWebinar({
        ...formData,
        photo3: selectedPhoto,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview3(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    }
  };

  // Función de envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar que las fechas seleccionadas sean válidas
    if (formData.fechaInicio >= formData.fechaFin) {
      setError("La fecha de inicio debe ser anterior a la fecha de fin.");
      return;
    }

    setLoading(true);
    // Lógica para enviar el formulario
    try {
      const response = await axios.put(
        `${window.BACKEND_URL}Webinarios/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      if (response.status === 200) {
        alert("Webinario actualizado correctamente");
        navigate("/webinar");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error al actualizar el Webinario:", error);
      alert(
        "Error al actualizar el Webinario. Revisa la consola para más detalles."
      ); // Aviso al usuario
    } finally {
      setLoading(false);
    }
  };

  const handlePoenteChange = (value) => {
    setWebinar((prevData) => ({
      ...prevData,
      dponente: value,
    }));
  };
  const handleDescripcionChange = (value) => {
    setWebinar((prevData) => ({
      ...prevData,
      descripcion: value,
    }));
  };
  // Función para volver atrás
  const handleBack = () => {
    navigate("/webinar");
  };

  const handleCheckboxChange = () => {
    setWebinar((prevData) => ({
      ...prevData,
      estado: !formData.estado, // Cambiamos el estado actual
    }));
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Webinar</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="estado" className="form-label">
                      Estado del webinar:
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="estado"
                      name="estado"
                      checked={formData.estado}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="titulo" className="form-label">
                      Título del webinar:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="titulo"
                      name="titulo"
                      value={formData.titulo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="materia" className="form-label">
                      Materia del webinar:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="materia"
                      name="materia"
                      value={formData.materia}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="precio" className="form-label">
                      Precio del webinar:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="precio"
                      name="precio"
                      value={formData.precio}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="precio" className="form-label">
                      Nombre del ponente:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ponente"
                      name="ponente"
                      value={formData.ponente}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="descripcion" className="form-label">
                      Informacion del ponente:
                    </label>
                    <ReactQuill
                      value={formData.dponente}
                      onChange={handlePoenteChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="linkformulario" className="form-label">
                      Link del formulario de inscrpcion:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="linkformulario"
                      name="linkformulario"
                      value={formData.linkformulario}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="link" className="form-label">
                      Link del webinar:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="link"
                      name="link"
                      value={formData.link}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="fechaInicio" className="form-label">
                      Fecha de inicio:
                    </label>
                    <DatePicker
                      selected={
                        formData.fechaInicio
                          ? new Date(formData.fechaInicio)
                          : null
                      }
                      onChange={(value) =>
                        setWebinar({ ...formData, fechaInicio: value })
                      }
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Hora"
                      dateFormat="dd/MM/yyyy HH:mm"
                      className="form-control"
                      placeholderText="Seleccione fecha y hora de inicio"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="fechaFin" className="form-label">
                      Fecha de fin:
                    </label>
                    <DatePicker
                      selected={
                        formData.fechaFin ? new Date(formData.fechaFin) : null
                      }
                      onChange={handleFechaFinChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Hora"
                      dateFormat="dd/MM/yyyy HH:mm"
                      className="form-control"
                      placeholderText="Seleccione fecha y hora de inicio"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="photo" className="form-label">
                      Card:
                    </label>
                    <input
                      name="photo"
                      type="file"
                      className="form-control"
                      id="photo"
                      onChange={handlePhotoChange1}
                    />
                  </div>
                  {preview1 && (
                    <img
                      src={`${window.BACKEND_URL}uploads/${preview1}`}
                      alt="Preview"
                      style={{ maxWidth: "50%", height: "auto" }}
                    />
                  )}

                  <div className="mb-3">
                    <label htmlFor="photo2" className="form-label">
                      Baner:
                    </label>
                    <input
                      name="photo2"
                      type="file"
                      className="form-control"
                      id="photo2"
                      onChange={handlePhotoChange2}
                    />
                  </div>
                  {preview2 && (
                    <img
                      src={`${window.BACKEND_URL}uploads/${preview2}`}
                      alt="Preview"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}

                  <div className="mb-3">
                    <label htmlFor="photo2" className="form-label">
                      Foto del ponente:
                    </label>
                    <input
                      name="photo3"
                      type="file"
                      className="form-control"
                      id="photo3"
                      onChange={handlePhotoChange3}
                    />
                  </div>
                  {preview3 && (
                    <img
                      src={`${window.BACKEND_URL}uploads/${preview3}`}
                      alt="Preview"
                      style={{ maxWidth: "50%", height: "auto" }}
                    />
                  )}
                  {error && <div className="text-danger">{error}</div>}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="descripcion" className="form-label">
                  Descripción:
                </label>
                <ReactQuill
                  value={formData.descripcion}
                  onChange={handleDescripcionChange}
                />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBack}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={50}
                      width={50}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Editar Webinar <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editwebinar;
