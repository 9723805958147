import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay"; // Importa el nuevo componente
import $ from "jquery";

const Terminos = () => {
  const navigate = useNavigate();

  const [terminos, setTerminos] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTerminos = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}terminos/condiciones`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      setTerminos(response.data);
    } catch (error) {
      console.error("Error al obtener los baners:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getTerminos();
      } catch (error) {
        console.error("Error al obtener los baners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tablabaner.current || $.fn.DataTable.isDataTable(tablabaner.current))
        return;

      $(tablabaner.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const tablabaner = useRef(null);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${window.BACKEND_URL}terminos/condiciones/${id}`
      );
      if (response.status === 200) {
        alert("Término eliminado correctamente");
        getTerminos();
      } else {
        alert("Error al eliminar el término"); // Manejar cualquier otro estado de respuesta que no sea 200
      }
    } catch (error) {
      console.error("Error al eliminar el término:", error);
      alert("Error al eliminar el término"); // Mostrar un mensaje de error si ocurre un problema con la solicitud
    }
  };

  const EditTerminos = (terminoId) => {
    navigate(`/EditTerminos/${terminoId}`);
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <span className="float-end">
                  <Link to="/AddTerminos" className="btn btn-primary">
                    <i className="fa-solid fa-plus"></i> Agregar Baner
                  </Link>
                </span>
                <h5 className="card-title mb-0">Baner</h5>
              </div>
              <hr />

              <div className="card-body">
                <table className="table table-bordered" ref={tablabaner}>
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {terminos.map((termino) => (
                      <tr key={termino._id}>
                        <td>
                          <div
                            className="webinar-description"
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html: termino.Termino,
                            }}
                          ></div>
                        </td>
                        <td>
                          <button
                            className="btn btn-primary me-2"
                            onClick={() => EditTerminos(termino._id)}
                          >
                            <BsPencilSquare /> Editar
                          </button>
                          <button
                            onClick={() => handleDelete(termino._id)}
                            className="btn btn-danger"
                          >
                            <BsTrash3Fill /> Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Terminos;
