import React from "react";
import { Oval } from "react-loader-spinner";

const LoadingOverlay = ({ loading, text }) => {

  if (!loading) {
    return null;
  }

  return (
    <div className="centered-overlay">
      <div className="position-relative">
        <div className="translate-middle">
          <div className="loading-overlay text-center">
            <Oval color="#00BFFF" height={100} width={100} />
            {text && <p>{text}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay;
