import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Estilo por defecto de ReactQuill

const EditNorma = () => {
  const navigate = useNavigate();
  const [fotoCardPreview, setFotoCardPreview] = useState(null);
  const [fotoPortPreview, setFotoPortPreview] = useState(null);
  const { id } = useParams();
  const [normaData, setNormaData] = useState({
    publicado: true,
    orden: "",
    titulo: "",
    tags: "",
    resumen: "",
    foto_card: "",
    foto_port: "",
    archivopdf: "",
    body: "",
    slug: "",
  });

  const fetchNormaData = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}normatividad/${id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      if (!response.data) {
        throw new Error("Error al obtener la normativa");
      }

      setNormaData(response.data);
    } catch (error) {
      console.error("Error fetching normativa:", error);
      // Manejar el error, por ejemplo, mostrar un mensaje al usuario
    }
  };

  useEffect(() => {
    fetchNormaData();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNormaData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(normaData);
      await axios.put(`${window.BACKEND_URL}normatividad/${id}`, normaData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": sessionStorage.getItem("role"),
        },
      });
      navigate("/Normativa");
    } catch (error) {
      alert(
        "Hubo un error al actualizar la normativa. Por favor, inténtalo de nuevo más tarde."
      );
    }
  };

  const handleFotoCardPreview = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFotoCardPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleEditorChange = (value) => {
    setNormaData((prevData) => ({
      ...prevData,
      body: value,
    }));
  };
  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title mb-0 ">Editar Normativa</h3>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="titulo" className="form-label">
                    Título:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="titulo"
                    name="titulo"
                    value={normaData.titulo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="orden" className="form-label">
                    Orden:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="orden"
                    name="orden"
                    value={normaData.orden}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="titulo" className="form-label">
                    slug:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    name="slug"
                    value={normaData.slug}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="tags" className="form-label">
                    Tags:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="tags"
                    name="tags"
                    value={normaData.tags}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="resumen" className="form-label">
                    Resumen:
                  </label>
                  <textarea
                    className="form-control"
                    id="resumen"
                    name="resumen"
                    value={normaData.resumen}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              className="mb-3"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <label className="form-label">Cuerpo del documento:</label>
              <ReactQuill
                value={normaData.body}
                onChange={handleEditorChange}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Guardar Cambios
            </button>

            <button
              className="btn btn-secondary"
              onClick={() => {
                window.history.back();
              }}
            >
              cancelar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditNorma;
