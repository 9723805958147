import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const EditPassword = () => {
  const [password, setPassword] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const updatedPassword = {
        password: password,
      };

      const response = await fetch(
        `${window.BACKEND_URL}users/${id}/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
          body: JSON.stringify(updatedPassword),
        }
      );

      if (response.ok) {
        navigate("/users");
      } else {
        console.error(
          "Error al actualizar la contraseña:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error al procesar la solicitud:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Contraseña</h3>
          </div>
          <hr />
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Nueva Contraseña:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => navigate("/users")}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <>
                      <Oval color="#00BFFF" height={40} width={40} />{" "}
                    </>
                  ) : (
                    <>
                      <BsPencilSquare /> Actualizar Password
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
