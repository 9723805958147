import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay"; // Importa el nuevo componente
import $ from "jquery";

const Curso = () => {
  const [cursos, setCurso] = useState([]);
  const [docentes, setDocentes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const tablacur = useRef(null);
  const tabladoc = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        //await Getcurso();
        await Promise.all([Getcurso(), getDocentes()]);
        //initializeDataTable();
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tablacur.current || $.fn.DataTable.isDataTable(tablacur.current))
        return;

      $(tablacur.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const Getcurso = async () => {
    try {
      const respuesta = await axios.get(
        `${window.BACKEND_URL}curso/listo/todo`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      if (respuesta.status >= 200 && respuesta.status < 300) {
        setCurso(respuesta.data);
      } else {
        throw new Error("Error al cargar los datos");
      }
    } catch (error) {
      console.error("Error al cargar los cursos:", error);
    }
  };

  const getDocentes = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}users?role=Docente`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setDocentes(response.data);
    } catch (error) {
      console.error("Error fetching docentes:", error);
    }
  };

  const handleDocuments = (cursoId) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf, .doc, .docx"; // Puedes ajustar las extensiones permitidas
    input.onchange = (event) => handleFileUpload(event.target.files, cursoId);
    input.click();
  };

  const handleTareas = (cursoId) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".zip, .rar"; // Puedes ajustar las extensiones permitidas
    input.onchange = (event) => handleFileUpload(event.target.files, cursoId);
    input.click();
  };

  const handleFileUpload = async (files, cursoId) => {
    try {
      const formData = new FormData();
      formData.append("file", files[0]); // Solo permitimos la carga de un archivo por ahora

      alert("Archivo subido exitosamente");
    } catch (error) {
      console.error("Error al subir el archivo", error);
    }
  };

  const Editcurso = (cursoId) => {
    navigate(`/Editcurso/${cursoId}`);
  };

  const Addcurso = () => {
    navigate("/Addcurso");
  };

  const AddBaner = (cursoId) => {
    navigate(`/addBanerCurso/${cursoId}`);
  };

  const Addtarea = (cursoId) => {
    navigate(`/Addtarea/${cursoId}`);
  };

  const Adddocumento = (cursoId) => {
    navigate(`/Adddocumento/${cursoId}`);
  };

  const Addlink = (cursoId) => {
    navigate(`/Addlink/${cursoId}`);
  };

  const EditCursoFoto = (cursoId) => {
    navigate(`/EditCursoFoto/${cursoId}`);
  };

  const EditCursoBaner = (cursoId) => {
    navigate(`/EditCursoBaner/${cursoId}`);
  };

  const EditCursoBrochure = (cursoId) => {
    navigate(`/EditCursoBrochure/${cursoId}`);
  };
  const AddModulo = (cursoId) => {
    navigate(`/Modulo/${cursoId}`);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}curso/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });
      const deletedCurso = cursos.find((curso) => curso._id === id);
      setDeleteMessage(`Curso "${deletedCurso.nombre}" eliminada`);
      setShowModal(true);
      setCurso(cursos.filter((curso) => curso._id !== id));

      // Cerrar el modal automáticamente después de 2 segundos
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    } catch (error) {
      console.error("Error al eliminar el curso", error);
    }
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="card">
          <div className="card-header">
            <span className="float-end">
              <button className="btn btn-primary" onClick={Addcurso}>
                <i className="fa-solid fa-plus"></i> Agregar Curso
              </button>
            </span>
            <h5 className="card-title mb-0">Cursos</h5>
          </div>
          <div className="card-body">
            <table className="table table-bordered" ref={tablacur}>
              <thead>
                <tr>
                  <th>Imagen</th>
                  <th>Titulo</th>
                  <th>Docente</th>
                  <th>Precio</th>
                  <th>Turno</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cursos.map((curso) => (
                  <tr key={curso._id}>
                    <td>
                      <img
                        src={`${window.BACKEND_URL}uploads/${curso.pathPortad}`}
                        alt={curso.nombre}
                        style={{ width: "150px", height: "auto" }}
                      />
                    </td>
                    <td>{curso.nombre}</td>
                    <td>
                      {docentes.find(
                        (docente) => docente._id === curso.docente_id
                      )?.name || "N/A"}
                    </td>
                    <td>{curso.precio.alumnos}</td>
                    <td>{curso.turno}</td>
                    <td className="text-center">
                      <div className="dropdown">
                        <a
                          className="btn btn-sm btn-primary"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fas fa-ellipsis-v fa-lg"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <a
                              className="dropdown-item text-primary"
                              onClick={() => Editcurso(curso._id)}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>{" "}
                              Editar
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item text-primary"
                              onClick={() => AddBaner(curso._id)}
                            >
                              <i className="fa-solid fa-image"></i> Agregar
                              carousel
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-primary"
                              onClick={() => Adddocumento(curso._id)}
                            >
                              <i class="fa-solid fa-folder"></i> Documentos
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-primary"
                              onClick={() => Addtarea(curso._id)}
                            >
                              <i className="fa-solid fa-file"></i> Tareas
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-primary"
                              onClick={() => Addlink(curso._id)}
                            >
                              <i className="fa-solid fa-link"></i> Links
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-primary"
                              onClick={() => EditCursoFoto(curso._id)}
                            >
                              <i className="fa-regular fa-image"></i> Actualizar
                              Foto
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-primary"
                              onClick={() => EditCursoBaner(curso._id)}
                            >
                              <i className="fa-solid fa-image"></i> Actualizar
                              Baner
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-primary"
                              onClick={() => EditCursoBrochure(curso._id)}
                            >
                              <i className="fa-solid fa-file-arrow-down"></i>{" "}
                              Actualizar Brochure
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-primary"
                              onClick={() => AddModulo(curso._id)}
                            >
                              <i className="fa-solid fa-file-arrow-down"></i>{" "}
                              Seccion Modulos
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item text-danger"
                              onClick={() => handleDelete(curso._id)}
                            >
                              <i className="fa-solid fa-trash"></i> Eliminar
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showModal && (
              <div className="modal" style={{ display: "block" }}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Mensaje de Confirmación</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>{deleteMessage}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Curso;
